import { Menubar } from "primereact/menubar";
import logo from "../assets/images/CAC-logo.png";
import { Button } from "primereact/button";
import { useNavigate, Link } from "react-router-dom";

const CustomMenu = () => {
  let navigate = useNavigate();

  const items = [
    {
      label: "Diplomatic and consular networks database",
      items: [
        {
          label: "Dashboard",
          command: (event) => {
            window.location.href =
              "https://experience.arcgis.com/experience/a748e8cbb5aa4d63b946131762d50d29/";
          },
        },
        {
          label: "City ranking",
          command: (event) => {
            navigate("city-ranking");
          },
        },
        {
          label: "Country rankings",
          command: (event) => {
            navigate("country-rankings");
          },
        },
        {
          label: "Methodology",
          command: (event) => {
            navigate("methodology");
          },
        },
        {
          label: "Update",
          command: (event) => {
            navigate("update");
          },
        },
      ],
    },
    {
      label: "Publications",
      items: [
        {
          label: "2024 Ranking Report",
          command: (event) => {
            navigate("2024-ranking-report");
          },
        },
        {
          label: "Authors",
          command: (event) => {
            navigate("authors");
          },
        },
        {
          label: "Reports",
          command: (event) => {
            navigate("reports");
          },
        },
        {
          label: "Submissions",
          command: (event) => {
            navigate("submissions");
          },
        },
      ],
    },
    {
      label: "Honorary consuls",
      items: [
        {
          label: "Who are honorary consuls?",
          command: (event) => {
            navigate("who-are-honorary-consuls");
          },
        },
        {
          label: "Honorary bibliography",
          command: (event) => {
            navigate("honorary-bibliography");
          },
        },
        {
          label: "Honorary corps directory",
          command: (event) => {
            navigate("honorary-corps-directory");
          },
        },
        {
          label: "Honorary jobs",
          command: (event) => {
            navigate("honorary-jobs");
          },
        },
        {
          label: "Honorary news",
          command: (event) => {
            navigate("honorary-news");
          },
        },
        {
          label: "Honorary spaces",
          command: (event) => {
            navigate("honorary-spaces");
          },
        },
        {
          label: "Henry Graham Greene",
          command: (event) => {
            navigate("henry-graham-greene");
          },
        },
      ],
    },
    {
      label: "Resources",
      items: [
        {
          label: "What are consular affairs?",
          command: (event) => {
            navigate("what-are-consular-affairs");
          },
        },
        {
          label: "What are diplomatic networks?",
          command: (event) => {
            navigate("what-are-diplomatic-networks");
          },
        },
        {
          label: "1961 and 1963 Vienna conventions",
          command: (event) => {
            navigate("1961-and-1963-vienna-conventions");
          },
        },
        {
          label: "Bibliography",
          command: (event) => {
            navigate("bibliography");
          },
        },
        {
          label: "Country profiles",
          command: (event) => {
            navigate("country-profiles");
          },
        },
        {
          label: "Daily press review",
          command: (event) => {
            navigate("daily-press-review");
          },
        },
        {
          label: "Did you know?",
          command: (event) => {
            navigate("did-you-know");
          },
        },
        {
          label: "Diplomatic directory ",
          command: (event) => {
            navigate("diplomatic-directory");
          },
        },
        {
          label: "Global Consular Forum",
          command: (event) => {
            navigate("global-consular-forum");
          },
        },
        {
          label: "Glossary",
          command: (event) => {
            navigate("glossary");
          },
        },
        {
          label: "Laws, regulations, and policies",
          command: (event) => {
            navigate("laws-regulations-and-policies");
          },
        },
        {
          label: "Interesting links",
          command: (event) => {
            navigate("interesting-links");
          },
        },
        {
          label: "Weekly newsletter",
          command: (event) => {
            navigate("weekly-newsletter");
          },
        },
      ],
    },
    {
      label: "The Center",
      items: [
        {
          label: "About",
          command: (event) => {
            navigate("about");
          },
        },
        {
          label: "Research fellows",
          command: (event) => {
            navigate("research-fellows");
          },
        },
        {
          label: "Correspondents",
          command: (event) => {
            navigate("correspondents");
          },
        },
        {
          label: "Partners",
          command: (event) => {
            navigate("partners");
          },
        },
        {
          label: "Donate",
          command: (event) => {
            navigate("donate");
          },
        },
        {
          label: "Notice",
          command: (event) => {
            navigate("notice");
          },
        },
        {
          label: "Contact",
          command: (event) => {
            navigate("contact");
          },
        },
      ],
    },
  ];

  const start = (
    <Link to="/">
      <img src={logo} alt="Image" className="cac-logo-size" />
    </Link>
  );

  const navigateToDonate = () => {
    navigate("/donate");
  };

  const end = (
    <Button
      label="Donate"
      className="button-donate"
      onClick={navigateToDonate}
    />
  );

  return (
    <header className="menu-bar">
      <div className="reduce-width">
        <Menubar model={items} start={start} end={end} />
      </div>
    </header>
  );
};

export default CustomMenu;
