import React, { useState, useEffect } from "react";
import { CountryService } from "../../services/CountryService";
import { Divider } from "primereact/divider";
import CityRankingTable from "../Common/CityRankingTable";
import { Link } from "react-router-dom";
import LastUpdate from "../Common/LastUpdate";
import { AddPageHeader } from "../Common/Utils";

const CityRankingTool = () => {
  const [cities, setCities] = useState([]);
  const countryService = new CountryService();

  useEffect(() => {
    countryService.getCitiesStatistics().then((data) => setCities(data));
  }, []);

  return (
    <div className="reduce-width">
      {AddPageHeader("City ranking")}
      <Divider align="center">
        <h1>City ranking</h1>
      </Divider>
      <div className="privacy-terms">
        {" "}
        <div className="flex justify-content-between">
          <div>
            The{" "}
            <Link
              to="/methodology"
              style={{
                color: "black",
                fontWeight: "bold",
              }}
            >
              methodology
            </Link>{" "}
            outlines the process of database development.
          </div>
          <LastUpdate />
        </div>
      </div>
      <br />
      <CityRankingTable data={cities} longDescription />
    </div>
  );
};

export default CityRankingTool;
