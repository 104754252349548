import React, { Component } from "react";
import { Divider } from "primereact/divider";
import { AddPageHeader } from "../Common/Utils";
import { Image } from "primereact/image";
import databaseMethodology from "../../assets/images/methodology.png";

class Methodology extends Component {
  render() {
    return (
      <div>
        {AddPageHeader("Methodology")}
        <div className="reduce-width">
          <Divider align="center">
            <h1>Methodology</h1>
          </Divider>
          <div className="mb-3">Click to download the .pdf document.</div>
          <a
            href="/pdf/database-methodology.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              src={databaseMethodology}
              alt="databasemethodology"
              width="40%"
            />
          </a>
        </div>
      </div>
    );
  }
}

export default Methodology;
