import React from "react";

import { Divider } from "primereact/divider";
import { Link } from "react-router-dom";
import BibliographyTable from "../Common/BibliographyTable";
import { AddPageHeader } from "../Common/Utils";

const Submissions = () => {
  return (
    <div className="reduce-width">
      {AddPageHeader("Submissions")}
      <Divider align="center">
        <h1>Submissions</h1>
      </Divider>
      <div className="privacy-terms">
        Would you like to publish an article, a book review, an opinion column,
        or a research report? Please feel free to{" "}
        <Link
          to="/contact"
          style={{
            color: "black",
            fontWeight: "bold",
          }}
        >
          contact
        </Link>{" "}
        the Consular Affairs Center for guidelines.
      </div>
    </div>
  );
};

export default Submissions;
