import React from "react";
import { Carousel } from "primereact/carousel";
import FacebookContainer from "./FacebookContainer";
import TwitterContainer from "./TwitterContainer";
import { Link } from "react-router-dom";

const VisualCarousel = () => {
  const visual = [
    {
      number: "1",
      link: "https://experience.arcgis.com/experience/a748e8cbb5aa4d63b946131762d50d29/",
    },
    {
      number: "2",
      link: "/daily-press-review",
    },
    {
      number: "3",
      link: "/submissions",
    },
  ];

  const socialNetworksTemplate = (data) => {
    return (
      <div>
        {data.type === "facebook" && <FacebookContainer />}
        {data.type === "twitter" && <TwitterContainer />}
      </div>
    );
  };

  const visualTemplate = (data) => {
    return (
      <Link
        to={data.link}
        style={{
          color: "black",
          fontWeight: "bold",
        }}
      >
        <img src={`images/home/${data.number}.png`} width="800em" />
      </Link>
    );
  };

  return (
    <div className="card">
      <Carousel
        value={visual}
        numVisible={1}
        numScroll={1}
        itemTemplate={visualTemplate}
      />
    </div>
  );
};

export default VisualCarousel;
