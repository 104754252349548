import React, { useState, useEffect } from "react";
import { CountryService } from "../../services/CountryService";
import { Divider } from "primereact/divider";
import CountryRankingTable from "../Common/CountryRankingTable";
import { Link } from "react-router-dom";
import LastUpdate from "../Common/LastUpdate";
import { SelectButton } from "primereact/selectbutton";
import { AddPageHeader } from "../Common/Utils";

const CountryRankingTool = () => {
  const [countries, setCountries] = useState([]);
  const [countriesDomestically, setCountriesDomestically] = useState([]);
  const [countryMode, setCountryMode] = useState("Overseas posts");
  const countryService = new CountryService();

  useEffect(() => {
    countryService.getCountriesStatistics().then((data) => setCountries(data));
    countryService
      .getCountryDomesticallyStatistics()
      .then((data) => setCountriesDomestically(data));
  }, []);

  const options = ["Overseas posts", "Hosted posts"];

  return (
    <div className="reduce-width">
      {AddPageHeader("Country rankings")}
      <Divider align="center">
        <h1>Country rankings</h1>
      </Divider>
      <div className="flex justify-content-between">
        <div>
          The{" "}
          <Link
            to="/methodology"
            style={{
              color: "black",
              fontWeight: "bold",
            }}
          >
            methodology
          </Link>{" "}
          outlines the process of database development.
        </div>
        <LastUpdate />
      </div>
      <SelectButton
        className="my-2"
        value={countryMode}
        options={options}
        onChange={(e) => setCountryMode(e.value)}
      />
      {countryMode === "Overseas posts" && (
        <CountryRankingTable data={countries} longDescription />
      )}
      {countryMode === "Hosted posts" && (
        <CountryRankingTable data={countriesDomestically} longDescription />
      )}
    </div>
  );
};

export default CountryRankingTool;
